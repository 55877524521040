import axios from "./Request/AxiosConfig";
import axiosUtils from "./Request/AxiosUtils";
import router from "../router";

export default class LoginServices {
  realizarLogin(pin, email, password) {
    let form = new FormData();
    form.append("pin", pin);
    form.append("email", email);
    form.append("password", password);

    return new Promise((resolve, reject) => {
      axios
        .post("auth/login", form)
        .then((resp) => {
          let data = resp.data;
          if (data.codigo !== 200) throw resp.data.mensaje;

          localStorage.setItem("evelynCRMtoken", data.datos.token);
          localStorage.setItem("pin", data.datos.pin);
          localStorage.setItem("autorId", data.datos.usuarioId);
          localStorage.setItem("nombreCorto", data.datos.nombreCorto);
          localStorage.setItem("zonaHoraria", data.datos.zonaHoraria);
          localStorage.setItem("paisRegionId", data.datos.paisRegionId);
          localStorage.setItem("paisRegionCodigo", data.datos.paisRegionCodigo);
          localStorage.setItem(
            "clienteNombreComercial",
            data.datos.clienteNombreComercial
          );
          localStorage.setItem("menu", "1");
          localStorage.setItem("perfil", data.datos.perfil);

          resolve();
        })
        .catch((error) => axiosUtils.axiosCatch(error, (err) => reject(err)));
    });
  }

  realizarLogout() {
    let form = new FormData();
    return new Promise((resolve, reject) => {
      axios
        .post("auth/logout", form)
        .then((resp) => {
          let data = resp.data;
          if (data.codigo !== 200) throw resp.data.mensaje;
          localStorage.clear();
          router.push({ name: "login" });
          resolve();
        })
        .catch((error) => axiosUtils.axiosCatch(error, (err) => reject(err)));
    });
  }
}
