
import LoginService from "../../Services/LoginService";
const loginService = new LoginService();
export default {
  name: "login-view",
  data() {
    return {
      pin: "",
      email: "",
      password: "",
      guardarSesion: false,
    };
  },
  mounted() {
    this.loader(false);
    this.axiosSourceToken = this.axiosCancelToken.source();
  },
  computed: {},
  methods: {
    convertirMayusculas(val) {
      this.pin = val.toUpperCase();
    },
    login() {
      this.loader(true);
      loginService
        .realizarLogin(this.pin, this.email, this.password)
        .then(() => this.$router.push("/home"))
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => this.loader(false));
    },
  },
};
